import ClassicExtension from "../../ClassicExtension";

export default class DependentSelectExtension extends ClassicExtension {

    attach($root: JQuery) {
        $root
            .find('[data-dependent-select]')
            .each((index, element) => {
                this.initElement(element);
            });
    }

    initElement(dependentSelectElement: HTMLSelectElement) {
        let parent = dependentSelectElement.form[dependentSelectElement.dataset.depends];
        let url = dependentSelectElement.dataset.url;

        // Set items from data attribute or null
        let items = JSON.parse(dependentSelectElement.dataset.items || 'null');

        parent.addEventListener('change', () => {
            dependentSelectElement.setAttribute('disabled', 'disabled');

            if (items) {
                this.updateSelect(dependentSelectElement, items[parent.dataset.id]);
            }

            if (url) {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => this.updateSelect(dependentSelectElement, data))
                    .then(() => dependentSelectElement.removeAttribute('disabled'))
                ;
            }
        });
    }

    updateSelect(select: HTMLSelectElement, items) {

        // Store selected values
        let selectedOptionValues = [];
        let selectedOptions = select.selectedOptions;

        for (let id in selectedOptions) {
            console.log(selectedOptions[id].value);
            selectedOptionValues.push(selectedOptions[id].value);
        }

        select.innerHTML = '';

        for (let id in items) {
            let el = document.createElement('option')

            el.innerText = items[id];
            el.setAttribute('value', id);

            if (selectedOptionValues.includes(id)) {
                // Restore selected values
                // If the value is not in the new list, it will not be selected
                el.setAttribute('selected', 'selected');
            }

            select.appendChild(el);
        }
    }

}