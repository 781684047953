// Styles
import "../style/index.scss"

// Globals
import {IziToast} from "izitoast";
declare global {
    interface Window {
        $: JQueryStatic,
        jQuery: JQueryStatic,
        Nette: Nette,
        iziToast: IziToast,
    }
}
// @ts-ignore create global $ and jQuery variables
global.$ = global.jQuery = $;


// Polyfills
import "core-js";
import "formdata-polyfill";
const toBlob = require('canvas-to-blob');
toBlob.init();


// Error reporting
//--------------------------------
// CONFIGURE SENTRY
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
		dsn: "https://e6e89160359640ad8e14cb590e7101e6@o1168082.ingest.sentry.io/6617125",
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.0,
	});
}



// Libs
import "popper.js";
import "bootstrap";
import "tether";
import "switchery";
import "intro.js";
import "screenfull";
import "select2";

import Nette from "nette-forms";
// @ts-ignore
window.Nette = Nette;

import "../lib/nette/nette.ajax.js";

import "../lib/ublaboo/datagrid";
import "../lib/ublaboo/datagrid-spinners";
import "../lib/ublaboo/datagrid-instant-url-refresh";

import "../lib/mousewheel/jquery.mousewheel.js";
import "../lib/asscrollbar/jquery-asScrollbar.js";
import "../lib/asscrollable/jquery-asScrollable.js";
import "../lib/ashoverscroll/jquery-asHoverScroll.js";
import "../lib/jquery-bindFirst/jquery-bindFirst.js";

import "../lib/bootstrap-table/bootstrap-table.min.js";
import "../lib/bootstrap-table/extensions/filter-control/bootstrap-table-filter-control.min.js";
import "../lib/bootstrap-table/locale/bootstrap-table-cs-CZ.js";
import "../lib/bootstrap-table/extensions/mobile/bootstrap-table-mobile.js";
import "../lib/slidepanel/jquery-slidePanel.js";


// App components
import "./src/appComponents.js";

// App extensions
import NetteExtensionRegistrar from "./src/extensions/NetteExtensionRegistrar";
import AntispamExtension from "./src/extensions/Antispam/js/AntispamExtension";
import BootstrapNestedMenu from "./src/extensions/BootstrapNestedMenu";
import ChangeValueTarget from "./src/extensions/ChangeValueTarget";
import ClickTarget from "./src/extensions/ClickTarget";
import ControlCollapseExtension from "./src/extensions/BackendControl/ControlCollapseExtension";
import ControlModalExtension from "./src/extensions/BackendControl/ControlModalExtension";
import Flatpickr from "./src/extensions/Flatpickr";
import FixUblabooGridScroll from "./src/extensions/FixUblabooGridScroll";
import Izitoast from "./src/extensions/Izitoast";
import Loading from "./src/extensions/Loading";
import Modals from "./src/extensions/Modals";
import Selectpicker from "./src/extensions/Selectpicker";
import StickyChildElement from "./src/extensions/StickyChildElement";
import Tooltip from "./src/extensions/Tooltip";
import PreventDoubleSubmitExtension from "./src/extensions/PreventDoubleSubmitExtension";

NetteExtensionRegistrar.addExtension(new AntispamExtension());
NetteExtensionRegistrar.addExtension(new BootstrapNestedMenu());
NetteExtensionRegistrar.addExtension(new ChangeValueTarget());
NetteExtensionRegistrar.addExtension(new ClickTarget());
NetteExtensionRegistrar.addExtension(new ControlCollapseExtension());
NetteExtensionRegistrar.addExtension(new ControlModalExtension());
NetteExtensionRegistrar.addExtension(new Flatpickr());
NetteExtensionRegistrar.addExtension(new FixUblabooGridScroll());
NetteExtensionRegistrar.addExtension(new Izitoast());
NetteExtensionRegistrar.addExtension(new Loading());
NetteExtensionRegistrar.addExtension(new Modals());
NetteExtensionRegistrar.addExtension(new ScrollToDashboardComponent());
NetteExtensionRegistrar.addExtension(new Selectpicker());
NetteExtensionRegistrar.addExtension(new StickyChildElement());
NetteExtensionRegistrar.addExtension(new Tooltip());
NetteExtensionRegistrar.addExtension(new PreventDoubleSubmitExtension());
NetteExtensionRegistrar.addExtension(new RemoteHorizontalScrollbar());
NetteExtensionRegistrar.addExtension(new DependentSelectExtension());
NetteExtensionRegistrar.register();

// Dashboard
import "./src/dashboardComponents"
import ScrollToDashboardComponent from "./src/extensions/ScrollToDashboardComponent";
import RemoteHorizontalScrollbar from "./src/extensions/RemoteHorizontalScrollbar";
import DependentSelectExtension from "./src/extensions/DependentSelect";


